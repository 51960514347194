export const SourceKind = {
    "UNKNOWN": {
        "value": 0,
        "label": "Unknown"
    },
    "MANUAL": {
        "value": 1,
        "label": "Manual"
    },
    "DATA_GOV": {
        "value": 2,
        "label": "data.gov"
    },
    "OPEN_CANADA_CA": {
        "value": 4,
        "label": "open.canada.ca"
    },
    "EUROPA_EU": {
        "value": 5,
        "label": "europa.eu"
    }
};

export const SourceKindLabels = {
    "0": "Unknown",
    "1": "Manual",
    "2": "data.gov",
    "4": "open.canada.ca",
    "5": "europa.eu"
};

export const SourceKindList = [
    {
        "name": "UNKNOWN",
        "label": "Unknown",
        "value": 0
    },
    {
        "name": "MANUAL",
        "label": "Manual",
        "value": 1
    },
    {
        "name": "DATA_GOV",
        "label": "data.gov",
        "value": 2
    },
    {
        "name": "OPEN_CANADA_CA",
        "label": "open.canada.ca",
        "value": 4
    },
    {
        "name": "EUROPA_EU",
        "label": "europa.eu",
        "value": 5
    }
];

export const SourceStatus = {
    "UNKNOWN": {
        "value": 0,
        "label": "Unknown"
    },
    "SCHEDULED": {
        "value": 1,
        "label": "Scheduled"
    },
    "PROCESSING": {
        "value": 2,
        "label": "Processing"
    },
    "SUCCESS": {
        "value": 3,
        "label": "Success"
    },
    "ERROR": {
        "value": 4,
        "label": "Error"
    },
    "TO_BE_SCRAPPED": {
        "value": 5,
        "label": "To be scrapped"
    }
};

export const SourceStatusLabels = {
    "0": "Unknown",
    "1": "Scheduled",
    "2": "Processing",
    "3": "Success",
    "4": "Error",
    "5": "To be scrapped"
};

export const SourceStatusList = [
    {
        "name": "UNKNOWN",
        "label": "Unknown",
        "value": 0
    },
    {
        "name": "SCHEDULED",
        "label": "Scheduled",
        "value": 1
    },
    {
        "name": "PROCESSING",
        "label": "Processing",
        "value": 2
    },
    {
        "name": "SUCCESS",
        "label": "Success",
        "value": 3
    },
    {
        "name": "ERROR",
        "label": "Error",
        "value": 4
    },
    {
        "name": "TO_BE_SCRAPPED",
        "label": "To be scrapped",
        "value": 5
    }
];

export const OrganizationApprovalStatus = {
    "UNKNOWN": {
        "value": 0,
        "label": "Unknown"
    },
    "APPROVED": {
        "value": 1,
        "label": "Approved"
    },
    "NOT_APPROVED": {
        "value": 2,
        "label": "Not Approved"
    }
};

export const OrganizationApprovalStatusLabels = {
    "0": "Unknown",
    "1": "Approved",
    "2": "Not Approved"
};

export const OrganizationApprovalStatusList = [
    {
        "name": "UNKNOWN",
        "label": "Unknown",
        "value": 0
    },
    {
        "name": "APPROVED",
        "label": "Approved",
        "value": 1
    },
    {
        "name": "NOT_APPROVED",
        "label": "Not Approved",
        "value": 2
    }
];

export const OrganizationKind = {
    "UNKNOWN": {
        "value": 0,
        "label": "Unknown"
    },
    "ORGANIZATION": {
        "value": 1,
        "label": "Organization"
    }
};

export const OrganizationKindLabels = {
    "0": "Unknown",
    "1": "Organization"
};

export const OrganizationKindList = [
    {
        "name": "UNKNOWN",
        "label": "Unknown",
        "value": 0
    },
    {
        "name": "ORGANIZATION",
        "label": "Organization",
        "value": 1
    }
];

export const OrganizationState = {
    "UNKNOWN": {
        "value": 0,
        "label": "Unknown"
    },
    "ACTIVE": {
        "value": 1,
        "label": "Active"
    },
    "CREATED": {
        "value": 2,
        "label": "Created"
    },
    "DORMANT": {
        "value": 3,
        "label": "Dormant"
    }
};

export const OrganizationStateLabels = {
    "0": "Unknown",
    "1": "Active",
    "2": "Created",
    "3": "Dormant"
};

export const OrganizationStateList = [
    {
        "name": "UNKNOWN",
        "label": "Unknown",
        "value": 0
    },
    {
        "name": "ACTIVE",
        "label": "Active",
        "value": 1
    },
    {
        "name": "CREATED",
        "label": "Created",
        "value": 2
    },
    {
        "name": "DORMANT",
        "label": "Dormant",
        "value": 3
    }
];

export const DatasetKind = {
    "UNKNOWN": {
        "value": 0,
        "label": "Unknown"
    },
    "GEOSPATIAL": {
        "value": 1,
        "label": "Geospatial"
    }
};

export const DatasetKindLabels = {
    "0": "Unknown",
    "1": "Geospatial"
};

export const DatasetKindList = [
    {
        "name": "UNKNOWN",
        "label": "Unknown",
        "value": 0
    },
    {
        "name": "GEOSPATIAL",
        "label": "Geospatial",
        "value": 1
    }
];

export const ResourceState = {
    "UNKNOWN": {
        "value": 0,
        "label": "Unknown"
    },
    "ACTIVE": {
        "value": 1,
        "label": "Active"
    }
};

export const ResourceStateLabels = {
    "0": "Unknown",
    "1": "Active"
};

export const ResourceStateList = [
    {
        "name": "UNKNOWN",
        "label": "Unknown",
        "value": 0
    },
    {
        "name": "ACTIVE",
        "label": "Active",
        "value": 1
    }
];

export const ResourceFormat = {
    "UNKNOWN": {
        "value": 0,
        "label": "Unknown"
    },
    "ACCESS_DB": {
        "value": 1,
        "label": "Access DB"
    },
    "API": {
        "value": 2,
        "label": "API"
    },
    "ARCGIS_REST": {
        "value": 3,
        "label": "ArcGIS GeoServices REST API"
    },
    "ATOM_FEED": {
        "value": 4,
        "label": "Atom Feed"
    },
    "BINARY": {
        "value": 5,
        "label": "Binary"
    },
    "BLENDER": {
        "value": 6,
        "label": "Blender"
    },
    "BMP": {
        "value": 7,
        "label": "BMP"
    },
    "CSV": {
        "value": 8,
        "label": "CSV"
    },
    "DAT": {
        "value": 9,
        "label": "DAT"
    },
    "DOC": {
        "value": 10,
        "label": "Doc"
    },
    "DOCX": {
        "value": 11,
        "label": "Docx"
    },
    "ECOWIN": {
        "value": 12,
        "label": "Ecowin Chart"
    },
    "ESRI_REST": {
        "value": 13,
        "label": "Ersi REST"
    },
    "EXE": {
        "value": 14,
        "label": "Exe"
    },
    "GEO_JSON": {
        "value": 15,
        "label": "GeoJSON"
    },
    "GIF": {
        "value": 16,
        "label": "GIF"
    },
    "GML": {
        "value": 17,
        "label": "GML"
    },
    "GTAR": {
        "value": 18,
        "label": "GTAR"
    },
    "GZ": {
        "value": 19,
        "label": "G-Zipped"
    },
    "HDF": {
        "value": 20,
        "label": "HDF"
    },
    "HTML": {
        "value": 21,
        "label": "HTML"
    },
    "JPG": {
        "value": 22,
        "label": "JPG"
    },
    "JSON": {
        "value": 23,
        "label": "JSON"
    },
    "JSON_API": {
        "value": 24,
        "label": "JSON API"
    },
    "KML": {
        "value": 25,
        "label": "KML"
    },
    "LAS": {
        "value": 26,
        "label": "LAS"
    },
    "LOTUS_ORGANIZER": {
        "value": 27,
        "label": "Lotus Organizer"
    },
    "MARKDOWN": {
        "value": 28,
        "label": "Markdown"
    },
    "MATHEMATICA": {
        "value": 29,
        "label": "Mathematica"
    },
    "MATLAB_WORKSPACE": {
        "value": 30,
        "label": "MATLAB"
    },
    "MSI": {
        "value": 31,
        "label": "Microsoft Installer"
    },
    "NET_CDF": {
        "value": 32,
        "label": "NetCDF"
    },
    "PDF": {
        "value": 33,
        "label": "PDF"
    },
    "PNG": {
        "value": 34,
        "label": "PNG"
    },
    "POSTSCRIPT": {
        "value": 35,
        "label": "postscript"
    },
    "PPT": {
        "value": 36,
        "label": "Powerpoint"
    },
    "PYTHON": {
        "value": 37,
        "label": "Python"
    },
    "QGIS": {
        "value": 38,
        "label": "QGIS"
    },
    "RAR": {
        "value": 39,
        "label": "RAR"
    },
    "RDF": {
        "value": 40,
        "label": "RDF"
    },
    "RSS": {
        "value": 41,
        "label": "RSS"
    },
    "SH": {
        "value": 42,
        "label": "SH"
    },
    "SID": {
        "value": 43,
        "label": "SID"
    },
    "STEP": {
        "value": 44,
        "label": "STEP"
    },
    "TAR": {
        "value": 45,
        "label": "TAR"
    },
    "TAR_GZ": {
        "value": 46,
        "label": "TAR GZipped"
    },
    "TEXT": {
        "value": 47,
        "label": "Text"
    },
    "TIFF": {
        "value": 48,
        "label": "TIFF"
    },
    "TSV": {
        "value": 49,
        "label": "TSV"
    },
    "WCS": {
        "value": 50,
        "label": "WCS"
    },
    "WFS": {
        "value": 51,
        "label": "WFS"
    },
    "WMS": {
        "value": 52,
        "label": "WMS"
    },
    "XLS": {
        "value": 53,
        "label": "XLS"
    },
    "XLSX": {
        "value": 54,
        "label": "xlsx"
    },
    "XML": {
        "value": 55,
        "label": "XML"
    },
    "XYZ": {
        "value": 56,
        "label": "XYZ"
    },
    "ZIP": {
        "value": 57,
        "label": "ZIP"
    },
    "ZIPPED_CSV": {
        "value": 58,
        "label": "Zipped CSV"
    },
    "ZIPPED_DAT": {
        "value": 59,
        "label": "Zipped DAT"
    },
    "DTA": {
        "value": 60,
        "label": "Sage DB"
    },
    "SHP": {
        "value": 61,
        "label": "ESRI Shape"
    },
    "CSS": {
        "value": 62,
        "label": "CSS"
    },
    "ODS": {
        "value": 63,
        "label": "ODS"
    },
    "VEC": {
        "value": 64,
        "label": "Vectrex"
    },
    "XLSB": {
        "value": 65,
        "label": "Excel Binary Book"
    },
    "POR": {
        "value": 66,
        "label": "POR"
    },
    "SPSS": {
        "value": 67,
        "label": "SPSS"
    },
    "SYS": {
        "value": 68,
        "label": "SYS"
    },
    "ADF": {
        "value": 69,
        "label": "Amiga Floppy Disks"
    },
    "FITS": {
        "value": 70,
        "label": "Flexible Image Transport System"
    },
    "SAS": {
        "value": 71,
        "label": "SAS"
    },
    "R": {
        "value": 72,
        "label": "R"
    },
    "SD2": {
        "value": 73,
        "label": "SD2"
    },
    "TXT": {
        "value": 74,
        "label": "TXT"
    },
    "MAT": {
        "value": 75,
        "label": "MAT"
    },
    "SGML": {
        "value": 76,
        "label": "SGML"
    },
    "MP4": {
        "value": 77,
        "label": "MP4"
    },
    "XSL": {
        "value": 78,
        "label": "XSL"
    },
    "LYR": {
        "value": 79,
        "label": "LYR"
    }
};

export const ResourceFormatLabels = {
    "0": "Unknown",
    "1": "Access DB",
    "2": "API",
    "3": "ArcGIS GeoServices REST API",
    "4": "Atom Feed",
    "5": "Binary",
    "6": "Blender",
    "7": "BMP",
    "8": "CSV",
    "9": "DAT",
    "10": "Doc",
    "11": "Docx",
    "12": "Ecowin Chart",
    "13": "Ersi REST",
    "14": "Exe",
    "15": "GeoJSON",
    "16": "GIF",
    "17": "GML",
    "18": "GTAR",
    "19": "G-Zipped",
    "20": "HDF",
    "21": "HTML",
    "22": "JPG",
    "23": "JSON",
    "24": "JSON API",
    "25": "KML",
    "26": "LAS",
    "27": "Lotus Organizer",
    "28": "Markdown",
    "29": "Mathematica",
    "30": "MATLAB",
    "31": "Microsoft Installer",
    "32": "NetCDF",
    "33": "PDF",
    "34": "PNG",
    "35": "postscript",
    "36": "Powerpoint",
    "37": "Python",
    "38": "QGIS",
    "39": "RAR",
    "40": "RDF",
    "41": "RSS",
    "42": "SH",
    "43": "SID",
    "44": "STEP",
    "45": "TAR",
    "46": "TAR GZipped",
    "47": "Text",
    "48": "TIFF",
    "49": "TSV",
    "50": "WCS",
    "51": "WFS",
    "52": "WMS",
    "53": "XLS",
    "54": "xlsx",
    "55": "XML",
    "56": "XYZ",
    "57": "ZIP",
    "58": "Zipped CSV",
    "59": "Zipped DAT",
    "60": "Sage DB",
    "61": "ESRI Shape",
    "62": "CSS",
    "63": "ODS",
    "64": "Vectrex",
    "65": "Excel Binary Book",
    "66": "POR",
    "67": "SPSS",
    "68": "SYS",
    "69": "Amiga Floppy Disks",
    "70": "Flexible Image Transport System",
    "71": "SAS",
    "72": "R",
    "73": "SD2",
    "74": "TXT",
    "75": "MAT",
    "76": "SGML",
    "77": "MP4",
    "78": "XSL",
    "79": "LYR"
};

export const ResourceFormatList = [
    {
        "name": "UNKNOWN",
        "label": "Unknown",
        "value": 0
    },
    {
        "name": "ACCESS_DB",
        "label": "Access DB",
        "value": 1
    },
    {
        "name": "API",
        "label": "API",
        "value": 2
    },
    {
        "name": "ARCGIS_REST",
        "label": "ArcGIS GeoServices REST API",
        "value": 3
    },
    {
        "name": "ATOM_FEED",
        "label": "Atom Feed",
        "value": 4
    },
    {
        "name": "BINARY",
        "label": "Binary",
        "value": 5
    },
    {
        "name": "BLENDER",
        "label": "Blender",
        "value": 6
    },
    {
        "name": "BMP",
        "label": "BMP",
        "value": 7
    },
    {
        "name": "CSV",
        "label": "CSV",
        "value": 8
    },
    {
        "name": "DAT",
        "label": "DAT",
        "value": 9
    },
    {
        "name": "DOC",
        "label": "Doc",
        "value": 10
    },
    {
        "name": "DOCX",
        "label": "Docx",
        "value": 11
    },
    {
        "name": "ECOWIN",
        "label": "Ecowin Chart",
        "value": 12
    },
    {
        "name": "ESRI_REST",
        "label": "Ersi REST",
        "value": 13
    },
    {
        "name": "EXE",
        "label": "Exe",
        "value": 14
    },
    {
        "name": "GEO_JSON",
        "label": "GeoJSON",
        "value": 15
    },
    {
        "name": "GIF",
        "label": "GIF",
        "value": 16
    },
    {
        "name": "GML",
        "label": "GML",
        "value": 17
    },
    {
        "name": "GTAR",
        "label": "GTAR",
        "value": 18
    },
    {
        "name": "GZ",
        "label": "G-Zipped",
        "value": 19
    },
    {
        "name": "HDF",
        "label": "HDF",
        "value": 20
    },
    {
        "name": "HTML",
        "label": "HTML",
        "value": 21
    },
    {
        "name": "JPG",
        "label": "JPG",
        "value": 22
    },
    {
        "name": "JSON",
        "label": "JSON",
        "value": 23
    },
    {
        "name": "JSON_API",
        "label": "JSON API",
        "value": 24
    },
    {
        "name": "KML",
        "label": "KML",
        "value": 25
    },
    {
        "name": "LAS",
        "label": "LAS",
        "value": 26
    },
    {
        "name": "LOTUS_ORGANIZER",
        "label": "Lotus Organizer",
        "value": 27
    },
    {
        "name": "MARKDOWN",
        "label": "Markdown",
        "value": 28
    },
    {
        "name": "MATHEMATICA",
        "label": "Mathematica",
        "value": 29
    },
    {
        "name": "MATLAB_WORKSPACE",
        "label": "MATLAB",
        "value": 30
    },
    {
        "name": "MSI",
        "label": "Microsoft Installer",
        "value": 31
    },
    {
        "name": "NET_CDF",
        "label": "NetCDF",
        "value": 32
    },
    {
        "name": "PDF",
        "label": "PDF",
        "value": 33
    },
    {
        "name": "PNG",
        "label": "PNG",
        "value": 34
    },
    {
        "name": "POSTSCRIPT",
        "label": "postscript",
        "value": 35
    },
    {
        "name": "PPT",
        "label": "Powerpoint",
        "value": 36
    },
    {
        "name": "PYTHON",
        "label": "Python",
        "value": 37
    },
    {
        "name": "QGIS",
        "label": "QGIS",
        "value": 38
    },
    {
        "name": "RAR",
        "label": "RAR",
        "value": 39
    },
    {
        "name": "RDF",
        "label": "RDF",
        "value": 40
    },
    {
        "name": "RSS",
        "label": "RSS",
        "value": 41
    },
    {
        "name": "SH",
        "label": "SH",
        "value": 42
    },
    {
        "name": "SID",
        "label": "SID",
        "value": 43
    },
    {
        "name": "STEP",
        "label": "STEP",
        "value": 44
    },
    {
        "name": "TAR",
        "label": "TAR",
        "value": 45
    },
    {
        "name": "TAR_GZ",
        "label": "TAR GZipped",
        "value": 46
    },
    {
        "name": "TEXT",
        "label": "Text",
        "value": 47
    },
    {
        "name": "TIFF",
        "label": "TIFF",
        "value": 48
    },
    {
        "name": "TSV",
        "label": "TSV",
        "value": 49
    },
    {
        "name": "WCS",
        "label": "WCS",
        "value": 50
    },
    {
        "name": "WFS",
        "label": "WFS",
        "value": 51
    },
    {
        "name": "WMS",
        "label": "WMS",
        "value": 52
    },
    {
        "name": "XLS",
        "label": "XLS",
        "value": 53
    },
    {
        "name": "XLSX",
        "label": "xlsx",
        "value": 54
    },
    {
        "name": "XML",
        "label": "XML",
        "value": 55
    },
    {
        "name": "XYZ",
        "label": "XYZ",
        "value": 56
    },
    {
        "name": "ZIP",
        "label": "ZIP",
        "value": 57
    },
    {
        "name": "ZIPPED_CSV",
        "label": "Zipped CSV",
        "value": 58
    },
    {
        "name": "ZIPPED_DAT",
        "label": "Zipped DAT",
        "value": 59
    },
    {
        "name": "DTA",
        "label": "Sage DB",
        "value": 60
    },
    {
        "name": "SHP",
        "label": "ESRI Shape",
        "value": 61
    },
    {
        "name": "CSS",
        "label": "CSS",
        "value": 62
    },
    {
        "name": "ODS",
        "label": "ODS",
        "value": 63
    },
    {
        "name": "VEC",
        "label": "Vectrex",
        "value": 64
    },
    {
        "name": "XLSB",
        "label": "Excel Binary Book",
        "value": 65
    },
    {
        "name": "POR",
        "label": "POR",
        "value": 66
    },
    {
        "name": "SPSS",
        "label": "SPSS",
        "value": 67
    },
    {
        "name": "SYS",
        "label": "SYS",
        "value": 68
    },
    {
        "name": "ADF",
        "label": "Amiga Floppy Disks",
        "value": 69
    },
    {
        "name": "FITS",
        "label": "Flexible Image Transport System",
        "value": 70
    },
    {
        "name": "SAS",
        "label": "SAS",
        "value": 71
    },
    {
        "name": "R",
        "label": "R",
        "value": 72
    },
    {
        "name": "SD2",
        "label": "SD2",
        "value": 73
    },
    {
        "name": "TXT",
        "label": "TXT",
        "value": 74
    },
    {
        "name": "MAT",
        "label": "MAT",
        "value": 75
    },
    {
        "name": "SGML",
        "label": "SGML",
        "value": 76
    },
    {
        "name": "MP4",
        "label": "MP4",
        "value": 77
    },
    {
        "name": "XSL",
        "label": "XSL",
        "value": 78
    },
    {
        "name": "LYR",
        "label": "LYR",
        "value": 79
    }
];

