import "bootstrap-icons/font/bootstrap-icons.css";
import { ArrowLeft, SquareArrowOutUpRight } from "lucide-react";
import React from "react";
import { Button, Card, Container, ListGroup, Stack } from "react-bootstrap";
import { graphql, useLazyLoadQuery } from "react-relay";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ResourceFormatList } from "../choices";
import Footer from "../components/footer";
import "../styles/detail.css";

const DatasetDetailQuery = graphql`
  query detailDatasetsQuery($name: String!) {
    dataset(name: $name) {
      id
      title
      notes
      url
      sourceKind
      categories {
        id
        name
        displayName
      }
      tags {
        id
        name
        displayName
      }
      topics {
        id
        name
        displayName
      }
      resources {
        id
        name
        format
        url
        description
      }
    }
  }
`;

interface Topic {
  id: string;
  name: string;
  displayName: string;
}

interface Category {
  id: string;
  name: string;
  displayName: string;
}

interface Tag {
  id: string;
  name: string;
  displayName: string;
}

interface Resource {
  id: string;
  name: string;
  format: string;
  url: string;
  description: string;
}

interface Dataset {
  id: string;
  title: string;
  notes: string;
  url?: string;
  sourceKind?: string;
  categories?: Category[];
  tags?: Tag[];
  topics?: Topic[];
  resources?: Resource[];
}

interface DatasetDetailVariables {
  name: any;
}

interface DatasetDetailResponse {
  variables: DatasetDetailVariables;
  response: {
    dataset: Dataset;
  };
}

const DatasetDetail: React.FC = () => {
  const { name } = useParams<{ name: string }>();
  const navigate = useNavigate();

  const { dataset } = useLazyLoadQuery<DatasetDetailResponse>(
    DatasetDetailQuery,
    { name },
  );

  const handleGoBack = () => {
    navigate(-1);
  };

  if (!dataset) {
    return null;
  }

  return (
    <div className="d-flex flex-column min-vh-100">
      <Container className="p-2 flex-grow-1">
        <Card
          border="light"
          className={`d-flex mb-4 border-black ${
            window.innerWidth < 576 ? "p-2" : "p-4"
          }`}
        >
          <Stack
            direction="horizontal"
            className="gap-3 mb-3 d-flex align-items-center"
          >
            <Button
              variant="light"
              className="h-50 border-0 detail-back-btn"
              onClick={handleGoBack}
            >
              <ArrowLeft />
            </Button>
            <Card.Title className="fs-4 ms-3 fw-bold mt-2">
              {dataset.title}
              {/* Render source kind               <Button
                className="h-10 p-1 border-0 rounded-pill ms-2 justify-content-center align-items-center"
                style={{ backgroundColor: "#07B596" }}
              >
                {dataset.sourceKind && SourceKindLabels[dataset.sourceKind]}
              </Button>
          */}
            </Card.Title>
          </Stack>
          <Card.Body>
            {/* Render topics */}
            {dataset.topics && dataset.topics.length > 0 && (
              <Stack
                direction="horizontal"
                className="d-flex overflow-hidden mt-3 flex-wrap"
                gap={2}
              >
                <label>Topics: </label>
                {dataset.topics.map((topic: Topic) => (
                  <Link
                    key={topic.id}
                    to={`/?topics=${topic.name}`}
                    className="text-decoration-none"
                  >
                    <Button
                      variant="light"
                      className="border-0 p-0 m-0 tag-btn"
                      key={topic.id}
                    >
                      <label className="p-2 rounded-3 border-0 ">
                        {topic.displayName || topic.name}
                      </label>
                    </Button>
                  </Link>
                ))}
              </Stack>
            )}

            {/* Render categories */}
            {dataset.categories && dataset.categories.length > 0 && (
              <Stack
                direction="horizontal"
                className="d-flex overflow-hidden mt-3 flex-wrap"
                gap={2}
              >
                <label>Categories:</label>
                {dataset.categories.slice(0, 7).map((category: Category) => (
                  <Link
                    key={category.id}
                    to={`/?categories=${category.name}`}
                    className="text-decoration-none"
                  >
                    <Button
                      variant="light"
                      className="border-0 p-0 m-0 tag-btn"
                      key={category.id}
                    >
                      <label className="p-2 rounded-3 border-0">
                        {category.displayName || category.name}
                      </label>
                    </Button>
                  </Link>
                ))}
              </Stack>
            )}

            {/* Render tags */}
            {dataset.tags && dataset.tags.length > 0 && (
              <Stack
                direction="horizontal"
                className="d-flex overflow-hidden mt-3 flex-wrap"
                gap={2}
              >
                <label>Tags:</label>
                {dataset.tags.slice(0, 8).map((tag: Tag) => (
                  <Link
                    key={tag.id}
                    to={`/?tags=${tag.name}`}
                    className="text-decoration-none "
                  >
                    <Button
                      variant="light"
                      className="border-0 p-0 m-0 tag-btn "
                      key={tag.id}
                    >
                      <label className="p-2 rounded-3 border-0 ">
                        {tag.displayName || tag.name}
                      </label>
                    </Button>
                  </Link>
                ))}
              </Stack>
            )}

            {/* Render dataset notes */}
            <p
              className="fs-6 mt-4"
              dangerouslySetInnerHTML={{ __html: dataset.notes }}
            />

            {/* Render dataset URL if available */}
            {dataset.url && <a href={dataset.url}>Dataset URL</a>}

            {/* Render dataset resources */}
            {dataset.resources && dataset.resources.length > 0 && (
              <ListGroup>
                <h2 className="fs-5 fw-semibold my-4">Downloads & Resources</h2>
                {dataset.resources.map((resource: Resource) => (
                  <ListGroup.Item
                    className={`d-flex overflow-hidden border rounded-1 align-items-center justify-content-between fs-6 ${
                      window.innerWidth < 576 ? "p-2" : "p-2"
                    } `}
                    key={resource.id}
                  >
                    <label
                      className={`d-flex overflow-hidden align-items-center justify-content-between fs-6 ${
                        window.innerWidth < 576 ? "p-2 " : "p-3 "
                      } `}
                    >
                      {resource.name} - {ResourceFormatList[parseInt(resource.format)].label}
                    </label>
                    <a className="text-decoration-none" href={resource.url}>
                      <Button
                        variant="light"
                        className={`d-flex justify-content-center align-items-center text-nowrap  ${
                          window.innerWidth < 576 ? "fs-6" : ""
                        } `}
                      >
                        <SquareArrowOutUpRight size={20} />
                        <label
                          className={`d-flex mx-2 ${
                            window.innerWidth < 576
                              ? "fs-6 text-decoration-none bg-white "
                              : ""
                          } `}
                        >
                          Visit Page
                        </label>
                      </Button>
                    </a>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            )}
          </Card.Body>
        </Card>
      </Container>
      <Footer />
    </div>
  );
};

export default DatasetDetail;
