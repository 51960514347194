import React, { Suspense, useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  ListGroup,
  Row,
  Stack,
} from "react-bootstrap";
import { graphql, usePreloadedQuery, useQueryLoader } from "react-relay";
import { Link, useSearchParams } from "react-router-dom";
import { SlidersHorizontal } from "lucide-react";
import Loading from "components/loading";
import Footer from "../components/footer";
import SearchFilter from "../components/searchfilter";

import "styles/filter.css";
import "styles/list.css";
import "../index.css";

// 1. Import your JS object as usual (no change to choices.js)
import { SourceKindLabels } from "../choices";

// 2. Relay query (example; adjust to match your schema)
const DatasetCount = graphql`
  query listDatasetCountQuery(
    $q: String
    $after: String
    $before: String
    $first: Int
    $last: Int
    $tags: [String!]
    $categories: [String!]
    $organizations: [String!]
    $topics: [String!]
    $sourceKind: Int
  ) {
    datasetCount(
      filter: {
        q: $q
        topics: $topics
        tags: $tags
        organizations: $organizations
        categories: $categories
        sourceKind: $sourceKind
      }
    )
    popularTags(
      filter: {
        q: $q
        topics: $topics
        tags: $tags
        organizations: $organizations
        categories: $categories
      }
    ) {
      edges {
        node {
          id
          name
          displayName
        }
      }
    }
    datasets(
      filter: {
        q: $q
        topics: $topics
        tags: $tags
        organizations: $organizations
        categories: $categories
        sourceKind: $sourceKind
      }
      first: $first
      last: $last
      after: $after
      before: $before
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          name
          id
          title
          notes
          sourceKind
          organization {
            title
          }
        }
      }
    }
  }
`;

function DatasetList() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [queryReference, loadQuery] = useQueryLoader(DatasetCount);

  // Local states
  const [qInput, setQInput] = useState(searchParams.get("q") || "");
  const [currentPage, setCurrentPage] = useState(1);
  const [resultsPerPage, setResultsPerPage] = useState(15);
  const [showModal, setShowModal] = useState(false);

  // Load the query with the correct params
  useEffect(() => {
    const sourceKindValue = searchParams.get("sourceKind");
    const sourceKind = sourceKindValue ? parseInt(sourceKindValue, 10) : null;

    const params: { [key: string]: string | number | null } = {};
    searchParams.forEach((value, key) => {
      params[key] = value;
    });
    params["sourceKind"] = sourceKind;
    params["first"] = resultsPerPage;

    loadQuery(params);
  }, [loadQuery, searchParams, resultsPerPage]);

  // Handle search form
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setSearchParams((prev) => {
      prev.set("q", qInput);
      return prev;
    });
  };

  // Show/hide filter modal
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  // Wait for query data
  if (!queryReference) {
    return <Loading />;
  }

  return (
    <div className="d-flex flex-column min-vh-100">
      <Container
        className="flex-grow-1 px-3 px-lg-5"
        style={{ fontFamily: "'Istok Web', sans-serif", maxWidth: "1200px" }}
      >
        <Row className="mb-3">
          <Col>
            <Form
              onSubmit={handleSubmit}
              className="w-100 d-flex align-items-center my-3"
            >
              <Row className="align-items-center w-100">
                <Col xs={12} md={8} className="mb-3 mb-md-0">
                  <div className="d-flex align-items-center p-1 rounded-pill border shadow-filter">
                    <Form.Control
                      type="text"
                      placeholder="Search..."
                      value={qInput}
                      className="p-1 m-1 border-0 shadow-none rounded-pill flex-grow-1"
                      onChange={(e) => setQInput(e.target.value)}
                    />
                    <Button
                      variant="light"
                      onClick={handleShowModal}
                      className="mx-1 border-light rounded-pill filter-btn-icon"
                    >
                      <SlidersHorizontal size={20} />
                    </Button>
                  </div>
                </Col>
                <Col xs={6} md={2} className="mb-3 mb-md-0">
                  <Button
                    type="submit"
                    variant="light"
                    className="filter-btn w-100"
                  >
                    Search
                  </Button>
                </Col>
                <Col xs={6} md={2}>
                  <Link to={"/"} className="w-100 d-inline-block">
                    <Button
                      type="reset"
                      variant="light"
                      className="filter-btn w-100"
                    >
                      Reset
                    </Button>
                  </Link>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>

        {queryReference && (
          <QueryResult
            queryReference={queryReference}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
            showModal={showModal}
            handleCloseModal={handleCloseModal}
            handleSubmit={handleSubmit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            resultsPerPage={resultsPerPage}
          />
        )}

        <SearchFilter
          setSearchParams={setSearchParams}
          handleCloseModal={handleCloseModal}
          searchParams={searchParams}
          showModal={showModal}
          qInput={qInput}
          setQInput={setQInput}
          handleSubmit={handleSubmit}
        />
      </Container>
      <Footer />
    </div>
  );
}

// ------------------ QueryResult component ------------------

interface QueryResultProps {
  queryReference: any;
  setSearchParams: (params: URLSearchParams) => void;
  searchParams: URLSearchParams;
  showModal: boolean;
  handleCloseModal: () => void;
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  resultsPerPage: number;
}

interface PageInfo {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string;
  endCursor: string;
}

// Adjust for your schema
type QueryResponse = {
  datasetCount: number;
  popularTags: {
    edges: Array<{
      node: { id: string; name: string; displayName: string };
    }>;
  };
  datasets: {
    pageInfo: PageInfo;
    edges: Array<{
      node: {
        name: string;
        id: string;
        title: string;
        notes: string;
        // Typically an Int in GraphQL => number in TS
        sourceKind: number | null;
        organization: { title: string };
      };
    }>;
  };
};

const QueryResult: React.FC<QueryResultProps> = ({
  queryReference,
  currentPage,
  setCurrentPage,
  setSearchParams,
  searchParams,
  resultsPerPage,
}) => {
  // 3. Cast to our expected response type
  const data = usePreloadedQuery(DatasetCount, queryReference) as QueryResponse;

  const { datasetCount, popularTags, datasets } = data;

  // Pagination
  const handlePageClick = (page: number) => {
    setCurrentPage(page);
    const newSearchParams = new URLSearchParams(searchParams.toString());

    if (page > currentPage) {
      newSearchParams.set("after", datasets.pageInfo.endCursor);
      newSearchParams.delete("before");
    } else if (page < currentPage) {
      newSearchParams.set("before", datasets.pageInfo.startCursor);
      newSearchParams.delete("after");
    }
    newSearchParams.set("first", resultsPerPage.toString());
    setSearchParams(newSearchParams);
  };

  // Tags
  const handleTagsChange = (selectedTopic: string) => {
    const newSearchParams = new URLSearchParams(searchParams.toString());
    newSearchParams.set("tag", selectedTopic);
    setSearchParams(newSearchParams);
  };

  return (
    <Suspense fallback={<div>Loading</div>}>
      <Row className="mb-4">
        {popularTags && (
          <Stack
            direction="horizontal"
            className="d-flex mt-3 align-items-center flex-wrap"
            gap={2}
          >
            <label className="popular-filter mb-3 mb-md-5 me-2">
              Popular Tags:{" "}
            </label>
            {popularTags.edges.map((edge) => (
              <Button
                variant="light"
                key={edge.node.id}
                onClick={() => handleTagsChange(edge.node.name)}
                className="d-flex align-items-center justify-content-center border-0 p-0 m-0 me-1 mb-3 mb-md-5 popular-btn"
              >
                <label className="fw-bold p-2 rounded-3 border-0">
                  {edge.node.displayName || edge.node.name}
                </label>
              </Button>
            ))}
          </Stack>
        )}
        <h1 className="popular-filter fs-4 fs-md-2">
          {datasetCount} datasets are available
        </h1>
      </Row>

      <Row>
        <Col>
          <Card.Body className="px-0">
            <ListGroup>
              <Card border="light" className="mb-4 border-0">
                <Card.Title className="popular-filter">
                  Popular Datasets
                </Card.Title>
              </Card>

              {/* 4. Convert the numeric sourceKind to string, use type-assertion */}
              {datasets.edges.map((edge) => {
                const numericSourceKind = edge.node.sourceKind;
                // Convert number -> string
                const labelKey =
                  numericSourceKind !== null ? String(numericSourceKind) : "";

                // Type assertion: treat SourceKindLabels as Record<string, string>
                // so TS won't complain about the index signature.
                const label = (SourceKindLabels as Record<string, string>)[
                  labelKey
                ];

                return (
                  <ListGroup.Item
                    key={edge.node.name}
                    className="mb-4 list-filter"
                  >
                    <Link
                      to={`/datasets/${edge.node.name}`}
                      className="text-decoration-none border-none"
                    >
                      <h4 className="list-title fw-semibold">
                        {edge.node.title}
                      </h4>
                      <h4 className="fs-6 fs-md-5 fw-medium gap-1 gray-color">
                        <label>{edge.node.organization.title}</label>
                        {label ? (
                          <Button
                            variant="light"
                            className="h-10 p-1 border-0 rounded-pill ms-2 
                                      justify-content-center align-items-center 
                                      source-kind-btn"
                          >
                            <label className="fw-semibold source-kind-detail-btn rounded-pill border-0">
                              {label}
                            </label>
                          </Button>
                        ) : null}
                      </h4>
                      <p
                        className="list-details text-muted mb-0"
                        style={{
                          display: "-webkit-box",
                          overflow: "hidden",
                          WebkitLineClamp: 2,
                          WebkitBoxOrient: "vertical",
                        }}
                      >
                        {edge.node.notes}
                      </p>
                    </Link>
                  </ListGroup.Item>
                );
              })}
            </ListGroup>
          </Card.Body>
        </Col>
      </Row>

      <Container className="d-flex justify-content-center align-items-center my-3">
        <Button
          variant="light"
          disabled={currentPage === 1}
          onClick={() => handlePageClick(currentPage - 1)}
          className="border-0 mx-3 fs-6 next-prev-btn"
        >
          Previous
        </Button>
        <Button
          variant="light"
          disabled={!datasets.pageInfo.hasNextPage}
          onClick={() => handlePageClick(currentPage + 1)}
          className="border-0 fs-6 next-prev-btn"
        >
          Next
        </Button>
      </Container>
    </Suspense>
  );
};

export default DatasetList;
